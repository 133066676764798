import React from 'react';
import Layout, { buildProps } from './index.js';
import Article from './main-components/Article';
import ArticlePreview from './main-components/ArticlePreview';
const Component = (props) => {
  const {
    navigation,
    footer,
    searchedGuides,
    pagePath,
    pageId,
    pages,
    paths,
    latestIds,
    location,
    categoryInformation
  } = buildProps(props)

  const addedProps = {
    pages,
    navigation,
    footer,
    searchedGuides,
    pagePath,
    pageId,
    paths,
    latestIds,
    categoryInformation
  }
  
  if (location.search.indexOf('preview=true') !== -1) {
    return <ArticlePreview {...addedProps} />
  }

  return <Article {...addedProps} />
}

const Page = (props) => {
  return (
    <Layout {...props}>
      <Component {...props} />
    </Layout>
  )
}

export default Page